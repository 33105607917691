$(document).ready(function(){
    $('#newsletter-subscription-modal').on('submit', 'form', function (_e) {
        _e.preventDefault();
        var url = $(this).attr('action');
        var type = $(this).attr('method');
        $.ajax({
            type: type,
            url: url,
            dataType: "json",
            data: $(this).serialize(),
            error: function (data) {
                $('.messages').empty();
                $('.messages').append('<div class="alert alert-danger">'+data.responseJSON.message+'</div>');
            },
            success: function (data) {
                $('.messages').empty();
                $('.messages').append('<div class="alert alert-success">'+data.message+'</div>');
                $('#newsletter-subscription-modal form').hide();
            }
        });
    });
});