import * as Routes from '../routes';

var formatDate = 'DD/MM/YYYY';
$(document).ready(function () {
    if ($(window).width() < 700) {
        singleDatePicker = true;
    }

    $('.searchbar-list li.searchbar-item .dropdown-search').on('click', function (event) {
        $(this).parent().toggleClass('open');
    });

    $('.searchbar-list .dropdown-menu.selectable-box').click(function (e) {
        e.stopPropagation();
    });

    $('.rent .main-search-form').on('submit', function (e) {
        if ($('#discount_code').val() !== '' && ($('#start_date').val() === '' || $('#end_date').val() === '')) {
            $('.searchbar-list .daterangepicker-input').trigger('click');
            $('.searchbar-list .daterangepicker-input').tooltip('show');
            e.preventDefault();
        }
    });

    $('.searchbar-list .dropdown').on('hide.bs.dropdown', function () {
        if ($(this).hasClass('min-max-box')) {
            updateItemMinMaxLabel($(this));
        }
        if ($(this).hasClass('min-max-select-box')) {
            updateItemMinMaxSelectLabel($(this));
        }
        if ($(this).hasClass('text-box')) {
            updateItemTextBoxLabel($(this));
        }
    });

    $('.searchbar-list .dropdown').on('shown.bs.dropdown', function (e) {
        e.preventDefault();
        if ($(this).find('input[type=text]').length > 0) {
            $(this).find('input[type=text]')[0].focus();
        }
    });

    $('.search-form-selector .form-selector-list a').on('click', function (e) {
        e.preventDefault();
        if ($(this).parents('li').hasClass('selected')) {
            return;
        }

        $('.search-form-container').each(function (index, item) {
            $(item).hide();
        });
        $('.search-form-selector li.form-selector').each(function (index, item) {
            $(item).toggleClass('selected');
        });
        $($(this).data('target')).show();
    });

    $('.searchbar-list .daterangepicker-input').tooltip({
        trigger: 'manual'
    })

    $('.searchbar-list .dropdown-select').on('click', 'a', function (e) {
        e.preventDefault();
        let container = $(this).parents('.dropdown')
        let $parent = $(this).parents('.dropdown-menu');
        $parent.find('a').removeClass('selected');
        $(this).toggleClass('selected');

        if (container.hasClass('min-max-select-box')) {
            container.find('input.min').val($(this).data('min'));
            container.find('input.max').val($(this).data('max'));
            updateItemMinMaxSelectLabel($(e));
        } else {
            let value = $(this).data('value');
            let input_name = $parent.data('input-name');
            $(this).parents('.searchbar-item').find("input[name='" + input_name + "']").val(value);
            updateItemDropdownSelectLabel($parent);
        }
    });

    $('.city-select a').on('click', function (e) {
        e.preventDefault();
        var value = $(this).data('value');
        var searchForm = $(this).parents('.searchbar-list');
        getAreas(value, searchForm);
    });

    $(function () {
        $('.searchbar-item .dropdown').each(function (index, e) {
            var $e = $(e);
            if ($e.hasClass('select-box')) {
                updateItemDropdownSelectLabel($(e));
                return;
            }
            if ($e.hasClass('min-max-box')) {
                updateItemMinMaxLabel($(e));
                return;
            }
            if ($e.hasClass('min-max-select-box')) {
                updateItemMinMaxSelectLabel($(e));
                return;
            }
            if ($e.hasClass('text-box')) {
                updateItemTextBoxLabel($(e));
                return;
            }
            if ($e.hasClass('daterange-box')) {
                updateItemDateRangeLabel($(e));
                return;
            }
        });
    });

    function getAreas(city, searchForm) {
        $.ajax({
            url: Routes.refresh_areas_path(current_locale) + '?city=' + city + '&type=' + searchForm.data('type'),
            method: 'GET',
            dataType: 'json'
        }).done(function (data) {
            var select = searchForm.find('.area-select');
            select.find('a').not(':first').remove();
            data.areas.forEach(function (area) {
                select.append('<a class="dropdown-item " data-value="' + area.code + '" href="#">' + area.name + '</a>');
            });
        });
    }

    function updateItemDropdownSelectLabel($item) {
        var $selectedElement = $item.find('.selected');
        var $button = $item.parents('.searchbar-item').find('.dropdown-toggle');

        if ($selectedElement.length > 0 && $selectedElement.data('value') !== '') {
            $button.find('.text').text($selectedElement.text());
            return;
        }

        $button.find('.text').text($button.data('original-text'));
    }

    function updateItemMinMaxLabel($item) {
        var $min = $item.find('input.min');
        var $max = $item.find('input.max');
        var $button = $item.parents('.searchbar-item').find('.dropdown-toggle');

        if ($min.val() !== '' && $max.val() !== '') {
            $button.find('.text').text($min.val() + ' - ' + $max.val() + ' ' + $max.data('suffix'));
            return;
        }
        if ($min.val() !== '') {
            $button.find('.text').text('> ' + $min.val() + ' ' + $min.data('suffix'));
            return;
        }

        if ($max.val() !== '') {
            $button.find('.text').text('< ' + $max.val() + ' ' + $max.data('suffix'));
            return;
        }

        $button.find('.text').text($button.data('original-text'));
    }

    function updateItemMinMaxSelectLabel($item) {
        let min = $item.find('input.min').val();
        let max = $item.find('input.max').val();
        let $button = $item.parents('.searchbar-item').find('.dropdown-toggle');
        let label = $button.data('original-text');
        let suffix = $button.data('suffix');

        if (parseInt(min) === 1 || parseInt(max) === 1) {
            label = $button.data('original-text-singular');
        }

        if (suffix !== '') {
            label = suffix;
        }

        if (min !== '' && max !== '') {
            $button.find('.text').text(min + ' - ' + max + ' ' + label);
            return;
        }
        if (min !== '') {
            $button.find('.text').text('≥ ' + min + ' ' + label);
            return;
        }

        if (max !== '') {
            $button.find('.text').text('≤ ' + max + ' ' + label);
            return;
        }

        $button.find('.text').text($button.data('original-text'));
    }

    function updateItemTextBoxLabel($item) {
        var $value = $item.find('input.value');
        var $button = $item.parents('.searchbar-item').find('.dropdown-toggle');

        if ($value.val() !== '') {
            $button.find('.text').text($value.data('prefix') + $value.val());
            return;
        }

        $button.find('.text').text($button.data('original-text'));
    }

    function updateItemDateRangeLabel($item) {
        var $start = $item.find('input.start');
        var $end = $item.find('input.end');
        var $button = $item.parents('.searchbar-item').find('.btn');

        if ($start.val() !== '' && $end.val() !== '') {
            var startLabel = moment($start.val(), commonFormat).format(shortFormatDate);
            var endLabel = moment($end.val(), commonFormat).format(shortFormatDate);
            $button.find('.text').text(startLabel + ' - ' + endLabel);
            return;
        }

        $button.find('.text').text($button.data('original-text'));
    }

    $('.searchbar-list .daterangepicker-input').daterangepicker({
        singleDatePicker: singleDatePicker,
        startDate: moment(),
        minDate: moment(),
        autoApply: true,
        opens: 'center',
        locale: {
            format: formatDate
        },
        isInvalidDate: function (date) {
            if (date.day() === 6) {
                return false;
            }
            return false;
        }
    });

    let tempDate = null;
    $('.searchbar-list .daterangepicker-input').on('apply.daterangepicker', function (ev, picker) {
        var startDate = moment(picker.startDate);
        var endDate = moment(picker.endDate);
        var $parent = $(this).parents('.searchbar-item');

        if (singleDatePicker) {
            if (tempDate === null || tempDate >= startDate) {
                tempDate = startDate.clone();
                picker.show();
                return;
            }

            if (tempDate.format(commonFormat) == startDate.format(commonFormat)) {
                picker.show();
                return;
            }

            startDate = tempDate;
            tempDate = null;
        }

        if (!singleDatePicker && startDate.format(commonFormat) == endDate.format(commonFormat)) {
            endDate = startDate.clone().add(1, 'week');
        }

        $parent.find('input[name="start_date"]').val(startDate.format(commonFormat));
        $parent.find('input[name="end_date"]').val(endDate.format(commonFormat));
        $('.daterangepicker-input').find('.text').text(startDate.format(shortFormatDate) + ' - ' + endDate.format(shortFormatDate));
    });
    $('.searchbar-list .daterangepicker-input').on('hide.daterangepicker', function (ev, picker) {
        $(this).tooltip('hide');
    });
});