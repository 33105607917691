$(document).ready(function () {
    $('.product-box-carousel').slick({
        adaptiveHeight: true,
        lazyLoad: 'ondemand',
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
    });

    $('.multiple-items-carousel').slick({
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        useTransform: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.multiple-items-carousel-variable-width').slick({
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true
    });

    $('.full-width-carousel').slick({
        infinite: true,
        speed: 300,
        dots: true
    });
});

function postMessageToPlayer(player, command) {
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
}

function playPauseVideo(slick, control) {
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "youtube") {
        switch (control) {
            case "play":
                postMessageToPlayer(player, {
                    event: "command",
                    func: "mute"
                });
                postMessageToPlayer(player, {
                    event: "command",
                    func: "playVideo"
                });
                break;
            case "pause":
                postMessageToPlayer(player, {
                    event: "command",
                    func: "pauseVideo"
                });
                break;
        }
    }
}