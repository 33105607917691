import jquery from "jquery"
import moment from "moment"
import "bootstrap"
import "daterangepicker"
import "slick-carousel"
import "lightgallery/dist/js/lightgallery.js"
import { I18n } from "i18n-js";
import translations from "./translations.json";

window.jQuery = jquery
window.moment = moment
window.$ = jquery
window.csrfTokenHeader = csrfTokenHeader

function csrfTokenHeader() {
    return {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
}